.finance-home {
  position: relative;
  padding: rem(24);
  color: $c-white;

  @include breakpoint(tablet) {
    width: 100%;
    max-width: rem(464);
    padding: 0;
    padding-right: rem(120);
  }

  &__title {
    max-width: rem(320);
    text-align: left;
    font-size: rem(44);
    font-weight: 200;
    line-height: rem(57);
    letter-spacing: 0em;
  }

  &__subtitle {
    text-align: left;
    margin-top: rem(15);
    font-size: rem(24);
    font-weight: 100;
    line-height: rem(28);

    @include breakpoint(tablet) {
      max-width: rem(380);
      width: 100%;
      margin-bottom: rem(25);
    }
  }
}

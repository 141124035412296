.score-context {
  max-width: rem(1000);
  width: 100%;
  margin: auto rem(32);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 rem(16);
  @include breakpoint(tablet) {
    flex-direction: row;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: flex-end;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include breakpoint(tablet) {
      flex-direction: row-reverse;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    max-width: rem(1000);
    width: 100%;
    flex: 1 1 auto;
    @include breakpoint(tablet) {
      flex: 1 2 auto;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @include breakpoint(tablet) {
      padding: 0 0 0 2rem;
    }
  }

  &__text {
    @extend %t-h5;
  }
}

.range {
  @extend %t-h5;
  padding: rem(8) 0;
  width: 100%;
  @include breakpoint(tablet) {
    padding: rem(32);
    padding: 1rem 0 0 2rem;
  }

  &__text {
    padding: rem(8);
    display: inline-flex;
    align-items: center;
    @include breakpoint(tablet) {
      padding: 0.5rem 0 0 0.5rem;
    }

    &__container {
      display: flex;
      justify-content: center;

      @include breakpoint(tablet) {
        justify-content: flex-end;
      }
    }
  }

  &__row {
    align-items: center;
  }

  &__bar {
    position: relative;
    background-color: $c-average-bar;
    height: 15px;
    border-radius: rem(100);
    &-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
      background-color: $c-average-bar-fill;
      border-radius: rem(100);
      //border-bottom-left-radius: rem(10);
      // &.full {
      //   border-radius: rem(10);
      // }
    }
    &-point {
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: $c-savings;

      &--small {
        height: 9px;
        width: 9px;
        border-radius: 100%;
        position: inherit;
        margin-right: 5px;
      }

      &--average {
        background-color: $c-average-point;
      }
    }
  }
}

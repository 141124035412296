.progress-bar {
  display: flex;
  flex-direction: column;

  .progress-wrapper {
    &.hovering {
      .progress-bar-level,
      .progress-bar-text {
        opacity: 0.5;

        &.highlight {
          opacity: 1;
        }
      }
    }
  }

  .progress-bar-row {
    display: flex;

    &.primary {
      height: rem(50);
    }
    &.secondary {
      height: rem(20);
    }
  }

  .progress-bar-level {
    &.essentials {
      background-color: $c-essentials;
    }
    &.wants {
      background-color: $c-wants;
    }
    &.savings {
      background-color: $c-savings;
    }
  }

  .progress-text {
    .progress-bar-text {
      min-width: rem(100);
      padding: rem(8) rem(2) 0;
    }
  }
}

.icon-scroll {
  display: flex;
  color: $c-white;
  background: $c-black;
  height: rem(80);
  width: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  @include breakpoint(tablet) {
    height: rem(100);
  }
  @include icon($icon-chevron-down) {
    color: $c-primary;
    margin-top: rem(10);
    font-weight: bold;
  }
}

.steps-counter {
  text-align: left;
  font-size: rem(23);
  font-weight: 400;
  color: $c-text-gray;

  &__steps {
    display: flex;
    @include breakpoint(desktop) {
      padding: 0 10%;
    }
  }

  &__current {
    margin-right: rem(7);
    color: #202b50;
  }

  &__total {
    margin-left: rem(7);
  }
}

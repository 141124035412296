//- import google fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

@font-face {
  font-family: 'UniversNextforHSBC';
  src: url('../../assets/fonts/UniversNextforHSBC-Thin.woff2') format('woff2'),
    url('../../assets/fonts/UniversNextforHSBC-Thin.woff') format('woff');
  font-weight: $f-thin;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversNextforHSBC';
  src: url('../../assets/fonts/UniversNextforHSBC-Light.woff2') format('woff2'),
    url('../../assets/fonts/UniversNextforHSBC-Light.woff') format('woff');
  font-weight: $f-light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversNextforHSBC';
  src: url('../../assets/fonts/UniversNextforHSBC-Regular.woff2') format('woff2'),
    url('../../assets/fonts/UniversNextforHSBC-Regular.woff') format('woff');
  font-weight: $f-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversNextforHSBC';
  src: url('../../assets/fonts/UniversNextforHSBC-Medium.woff2') format('woff2'),
    url('../../assets/fonts/UniversNextforHSBC-Medium.woff') format('woff');
  font-weight: $f-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('../../assets/fonts/#{$icomoon-font-family}.eot?tdj1zj');
  src: url('../../assets/fonts/#{$icomoon-font-family}.eot?tdj1zj#iefix') format('embedded-opentype'),
    url('../../assets/fonts/#{$icomoon-font-family}.ttf?tdj1zj') format('truetype'),
    url('../../assets/fonts/#{$icomoon-font-family}.woff?tdj1zj') format('woff'),
    url('../../assets/fonts/#{$icomoon-font-family}.svg?tdj1zj##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

.resilience-block {
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: rem(32);
  margin-right: 0;
  padding: rem(16) 0;
  background-color: $c-white;
  display: flex;
  flex-direction: column;
  @include breakpoint(tablet) {
    margin-bottom: 0;
  }

  &__tip {
    &--show {
      display: block;
      padding: 0 1.5rem 1.5rem 1.5rem;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        color: #db0011;
      }
    }
  }

  @include breakpoint(desktop) {
    max-width: rem(420);
  }

  &--excellent {
    border-top: 4px solid #{$c-excellent};
  }

  &--good {
    border-top: 4px solid #{$c-good};
  }

  &--bad {
    border-top: 4px solid #{$c-improvable};
  }

  &__data {
    display: flex;
    padding: rem(24) rem(24) 0 rem(24);
    flex-direction: column;
  }

  &__label {
    min-height: rem(26);
  }

  &__data-wrapper {
    display: flex;
  }

  &__data-block {
    &:first-of-type {
      padding-right: rem(24);
    }

    &:last-of-type {
      padding-left: rem(24);
      border-left: 1px solid $c-resilience-block;
    }

    &:only-child {
      border-left: none;
      padding-left: 0;
    }
  }

  &__label {
    @extend %t-h6;
    color: $c-help-text;
  }

  &__value {
    font: $f-thin rem(36) / rem(36) $f-text;
  }

  &:last-child {
    margin: 0;
  }
}

.rb-header {
  display: flex;
  align-items: center;
  padding: rem(24);

  &__icon {
    color: $c-select;

    .icon {
      font-size: rem(40);
    }
  }

  &__title {
    @extend %t-h4;
    flex-grow: 1;
    padding: 0 rem(16);
    @media (min-width: $bp-desktop) and (max-width: 1100px) {
      font-size: large;
    }
  }

  &__label {
    @extend %t-h6;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem(8) rem(12);
    @include breakpoint(tablet) {
      padding: 0 rem(12);
    }

    i {
      margin-right: rem(8);
    }

    &--excellent {
      i {
        color: #{$c-excellent};
      }
    }

    &--good {
      i {
        color: #{$c-good};
      }
    }

    &--bad {
      i {
        color: #{$c-improvable};
      }
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    padding: 0 rem(24);

    i {
      color: $c-border-select;
      font-size: rem(30);
    }
  }

  &__rating-wrapper {
    display: flex;
  }

  &__label-bold {
    font-weight: $f-regular;
  }

  &__rating-marker {
    display: flex;
    width: 33%;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 20px;

    &--good {
      margin: 0 auto;
      top: 14px;
    }

    &--excellent {
      top: 6px;
      margin-left: auto;
    }
  }

  &__rating-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: calc(33% - 3px);
    margin-right: 3px;
    text-align: center;
    height: 80px;
    margin-top: -30px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.trapezoid {
  &--improvable {
    fill: $c-improvable;
  }

  &--good {
    fill: $c-good;
  }

  &--excellent {
    fill: $c-excellent;
  }
}

.rb-tip {
  padding: 0 rem(24) rem(24) rem(24);

  &__title {
    font-weight: 350;
    font-size: 16px;
    line-height: 21px;
    color: #949494;
  }

  &__text {
    @extend %t-h6;
    color: $c-text-light;
  }

  &__link-wrapper {
    display: flex;
    margin-top: auto;
    padding: 0 rem(24) rem(8) rem(24);
  }

  &__link {
    text-align: center;
    font: $f-regular rem(16) / rem(16) $f-text;

    a {
      display: flex;
      justify-content: center;
      padding: rem(14) 0;
      color: #db0011;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }

    &:hover {
      a {
        color: #494949;
        text-decoration: none;
      }
    }
  }
}

.button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  padding-top: rem(12);
  padding-bottom: rem(12);
  font-weight: normal;

  @include breakpoint(tablet) {
    width: auto;
    padding-right: rem(63);
    padding-left: rem(63);
  }

  &--primary {
    background: $c-primary;
    color: $c-white;

    &:hover {
      background: $c-primary-dark;
    }
  }

  &--secondary {
    color: $c-primary;
    justify-content: flex-start;
  }

  &--small {
    font: normal rem(16) $f-text;
    padding: rem(12) rem(21);
  }

  &--big {
    font: medium rem(20) $f-text;
    width: 100%;
    padding-top: rem(22);
    padding-bottom: rem(22);

    @include breakpoint(tablet) {
      width: auto;
      padding-right: rem(46);
      padding-left: rem(46);
    }
  }

  &--link {
    color: $c-primary;
    align-items: baseline;
    justify-content: flex-start;
    font-weight: 500;

    i {
      font-size: rem(10);
      margin-right: rem(5);
      &::before {
        font-weight: 600;
        position: relative;
        top: rem(-1);
      }
    }
    .t-button {
      font-weight: 500;
    }
    &:hover {
      color: $c-select;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--icon-left {
    flex-direction: row;
    padding: 0;

    .button__icon {
      margin-right: rem(8);
    }
  }
  &--icon-right {
    flex-direction: row-reverse;

    .button__icon {
      margin-left: rem(8);
    }
  }
}

html,
body {
  height: 100%;
  // scroll-behavior: smooth;
}

html {
  &.blocked {
    body {
      overflow: hidden;
    }
  }
}

body {
  // padding-top: $u-header-height;

  // @include breakpoint(tablet) {
  //   padding-top: $u-header-height--sticky;
  // }

  // @include breakpoint(desktop) {
  //   padding-top: $u-header-height--desktop;
  // }
}

.wrapper-small {
  max-width: $g-max-width-small + $g-padding-mobile * 2;
  margin-left: auto;
  margin-right: auto;
  padding-left: $g-padding-mobile;
  padding-right: $g-padding-mobile;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}

* {
  margin: 0;
  padding: 0;

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

ul,
li {
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
}

button {
  background: none;
  border: 0;

  .with-mouse & {
    &:focus {
      outline: 0;
    }
  }
}

fieldset {
  border: 0;
  padding: 0;
}

input {
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.dedicated-team {
  display: block;
  position: relative;
  margin-top: rem(90);
  &__title {
    h2 {
      font-weight: 400;
      font-size: rem(32);
      line-height: rem(42);
    }
  }

  &__description {
    display: block;
    margin-top: rem(11);
    p {
      font-weight: 350;
      font-size: rem(20);
      line-height: rem(26);
      color: #767676;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    gap: rem(25);
    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }
}

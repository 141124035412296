.results {
  background-color: $c-bg-light-secondary;

  &__wrapper-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(40) $g-padding-mobile;
    background-color: $c-black;
  }
}

// COLORS
$c-primary: #db0011;
$c-primary-dark: #af000d;
$c-primary-light: rgba(219, 0, 17, 0.5);

$c-secondary: #4f46e5;

$c-black: #000;
$c-white: #ffffff;

$c-bg-gray: #e5e5e5;
$c-select: #333333;
$c-border-select: #d7d8d6;

$c-resilience-block: #e3e3e3;
$c-help-text: #767676;

$c-excellent: #0d6bbd;
$c-excellent-text: $c-white;

$c-good: #00847f;
$c-good-text: $c-white;

$c-bad: #ffbb33;
$c-bad-text: $c-black;

$c-dark-blue: rgb(32, 43, 80);
$c-dark-blue-secondary: rgba(32, 43, 80, 0.4);

$c-bg-light: $c-white;
$c-bg-light-secondary: rgba(240, 240, 240, 0.49);

$c-bg-dark: #1b1b1b;
$c-bg-dark-secondary: rgba(255, 255, 255, 0.1);

$c-border-light: 1px solid rgba(32, 43, 80, 0.1);
$c-border-dark: 1px solid rgba(237, 237, 237, 0.51);

$c-text: $c-black;
$c-text-light: rgb(118, 118, 118);

$c-button-disable: rgba(219, 0, 17, 0.5);
$c-text-disabled: rgba(83, 84, 97, 0.6);
$c-input-disabled: rgba(239, 239, 239, 0.3);

$c-text-fill: rgba(0, 0, 0, 0.7);

$bg-options: #f6f6f6;

$c-text-gray: #767676;
$c-essentials: #9451e0;
$c-wants: #4e9c2d;
$c-savings: #00a69d;
$c-savings-dark: #006f69;

$c-average-bar: rgba(220, 206, 255, 0.2);
$c-average-bar-fill: #9451e0;
$c-average-point: #fecb17;
$c-bg-bar: rgba(0, 0, 0, 0.1);

$c-button-tip-hover: #f3f3f3;

$c-bg-survey-experience: rgba(255, 255, 255, 0.15);

$c-bg-landing-content: #020207;

$c-bg-resources: #5c6064;
$c-bg-resources-content: #6b6b6b;

// RAG

$c-improvable: #ffbb33;
$c-good: #019b1b;
$c-excellent: #0d6bbd;

// TYPOGRAPHY
$f-base: 16;
$f-thin: 100;
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-bold: 700;

$f-text: 'UniversNextforHSBC';

// ICOMOON
$icomoon-font-family: 'icons' !default;

$icon-new_window: '\e91a';
$icon-marker: '\e919';
$icon-car: '\e90e';
$icon-publicTransport: '\e90f';
$icon-insuranceMedical: '\e910';
$icon-counciltax: '\e911';
$icon-childcare: '\e912';
$icon-other: '\e913';
$icon-utilities: '\e915';
$icon-housing: '\e916';
$icon-groceries: '\e917';
$icon-mediaEntertainment: '\e918';
$icon-pencil: '\e90d';
$icon-arrow: '\e900';
$icon-chevron-left: '\e901';
$icon-select-arrow: '\e914';
$icon-help: '\e902';
$icon-error-path1: '\e906';
$icon-error-path2: '\e907';
$icon-error-path3: '\e908';
$icon-chevron-down: '\e903';
$icon-borrowing: '\e904';
$icon-spending: '\e905';
$icon-arrow1: '\e90b';
$icon-light: '\e90c';
$icon-saving: '\e909';
$icon-touch: '\e90a';
$icon-umbrella: '\e91b';
$icon-download: '\e91c';
$icon-secure: '\e91d';
$icon-plan: '\e91e';
$icon-phone: '\e91f';
$icon-check: '\e921';
$icon-warning: '\e922';
$icon-edit: '\e923';
$icon-sixty: '\e924';
$icon-mobile: '\e925';
$icon-house: '\e926';
$icon-credit-card: '\e927';
$icon-dollar: '\e928';
$icon-seven: '\e929';

// BREAKPOINTS
// Small screen / phone
$bp-mobile: 599px;
// Medium screen / tablet
$bp-tablet: 768px;
// Large screen / tablet portrait
$bp-desktop: 1024px;
// Large screen / desktop
$bp-desktop-medium: 1200px;
// Extra large screen / wide desktop
$bp-desktop-large: 1440px;
// Extra large screen / extra wide desktop
$bp-desktop-extra-large: 1708px;

// Breakpoints in the form (name: length)
$breakpoints: (
  mobile: $bp-mobile,
  tablet: $bp-tablet,
  desktop: $bp-desktop,
  desktop-medium: $bp-desktop-medium,
  desktop-large: $bp-desktop-large,
  desktop-extra-large: $bp-desktop-extra-large,
) !default;

// GRID
$g-column: 90px; // Column Width
$g-gutter: 10px; // Gutter Width
$g-max-columns: 12; // Total Columns For Main Container
$g-max-width: 1400px;
$g-max-width-small: 1200px;

$g-padding-mobile: 24px;

// z-index

//height fixed header
$height-header: 90px;

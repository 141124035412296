.survey-experience {
  display: flex;
  flex-direction: column;
  color: $c-white;
  padding: rem(20) $g-padding-mobile;
  background-color: $c-bg-survey-experience;
  max-width: $g-max-width-small + $g-padding-mobile * 2;

  @include breakpoint(tablet) {
    flex-direction: row;
  }

  &__text-container {
    @include breakpoint(tablet) {
      width: 80%;
      padding-right: rem(32);
    }
  }

  &__link-container {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: rem(8);
    width: 100%;
    flex-direction: column;
    align-items: center;
    @include breakpoint(tablet) {
      width: 20%;
      justify-content: flex-end;
      align-self: flex-end;
      margin-top: 0;
      margin-bottom: rem(32);
    }
  }

  &__link {
    display: flex;
    width: 100%;
    height: rem(50);
    justify-content: center;
    align-items: center;
    background: $c-primary;
    color: $c-white;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  &__download {
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &:hover {
        text-decoration: none;
      }

      .icon {
        color: $c-primary;
        margin-left: rem(10);
      }
    }

    &-button {
      color: $c-white;
    }
  }
}

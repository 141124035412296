.input {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  flex: 1;
  &__wrapper {
    display: inline-flex;
    align-items: center;
    background-color: $c-white;
    flex: 1;
    &--step1 {
      margin: rem(5) 0;
    }
    &--border {
      border-radius: rem(5);
      border: rem(1) solid #e9eaed;
      padding: rem(10) rem(5);
      input {
        border: none !important;
      }
      .input__currency {
        margin-left: rem(25);
      }
    }
  }

  &--disabled {
    .input__wrapper {
      background-color: $c-input-disabled;
    }
  }

  &--error {
    .input__wrapper {
      outline: rem(1) solid $c-primary-dark;
    }
  }

  &__error {
    @extend %t-h6;
    position: absolute;
    margin-top: rem(4);
    font-weight: 500;
    color: $c-select;

    .icon {
      margin-right: rem(8);
      font-size: rem(22);
      color: $c-primary;
    }
  }

  &__border-error {
    border: rem(2) solid $c-primary !important;
  }

  input {
    flex-grow: 1;
    padding-left: rem(8);
    border: rem(1) solid #202b502a;
    border-radius: rem(6);
    color: #202b50;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    background-color: $c-white;
    flex: 1;

    &[type='number'] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: transparent;
    }

    &::placeholder {
      font-weight: 300;
      color: $c-text-disabled;
    }
  }

  &__checkbox {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-gap: 0.5em;
    width: 100%;
    cursor: pointer;

    input[type='checkbox'] {
      opacity: 0;
      width: 0.5em;
      height: 0.5em;
    }

    input[type='checkbox']:focus + &--control {
      box-shadow: 0 0 0 0.05rem $c-white, 0 0 0.15rem 0.1rem $c-primary;
    }

    input[type='checkbox']:checked + &--control svg {
      transform: scale(1);
    }

    &--input {
      display: grid;
      grid-template-areas: 'checkbox';
      place-self: center end;

      & > * {
        grid-area: checkbox;
      }
    }

    &--control {
      display: inline-grid;
      width: 0.5em;
      height: 0.5em;
      border: rem(1) solid $c-primary;
      color: $c-primary;

      svg {
        transition: transform 0.1s ease-in 0.25s;
        transform: scale(0);
      }
    }

    &--label {
      font-size: rem(18);
      line-height: rem(20);
    }
  }
}

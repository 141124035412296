.tip {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: rem(20);
  margin-right: 0;
  width: 100%;
  @include breakpoint(tablet) {
    flex-direction: row;
    margin-right: rem(32);
    margin-bottom: 0;
    width: 33%;
  }
  &:last-child {
    margin: 0;
  }
  &__wrapper {
    display: flex;
    background: $c-bg-light;
    color: $c-black;
    flex-direction: column;
    padding: rem(20);
    flex: 1 1 auto;
    width: 100%;
    @include breakpoint(tablet) {
      padding: rem(32);
    }
  }
  &__icon {
    color: $c-primary;
    font-size: rem(40);
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-left: rem(16);
    font: $f-light rem(17) / rem(30) $f-text;
    @include breakpoint(tablet) {
      font-size: rem(27);
      line-height: rem(38);
    }
  }
  a {
    color: $c-primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: auto;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

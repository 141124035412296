.howto {
  background: $c-bg-gray;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  &__wrapper-image {
    flex: 1 1 100%;
    @include breakpoint(tablet) {
      flex: 1 1 40%;
    }
  }
  &__wrapper-content {
    flex: 1 1 100%;
    padding: 0 rem(16);
    @include breakpoint(tablet) {
      flex: 2 1 60%;
      padding-left: rem(30);
      padding-right: rem(150);
    }

    p {
      margin-bottom: rem(22);
    }
  }
  &__item {
    margin-bottom: rem(30);
    p {
      margin-bottom: rem(22);
    }
    ul {
      margin-bottom: rem(22);
    }
    @include breakpoint(tablet) {
      margin-bottom: rem(60);
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
    @include breakpoint(tablet) {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    font-weight: $f-medium;
  }

  &__content-title {
    font: $f-medium rem(19) / rem(28) $f-text;
  }
}

.saving {
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop) {
    flex-direction: row;
  }

  &__tip {
    @include breakpoint(desktop) {
      background: $c-white;
      padding: $g-padding-mobile;
    }
  }

  &__percent {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__tab-content {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    p {
      font-weight: 500;
      @include breakpoint(desktop) {
        text-align: center;
      }
    }
  }

  &__tab-wrapper {
    border-bottom: 2px solid $c-white;
    display: flex;
    width: 100%;
  }

  &__percent-tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(32);
    @include breakpoint(desktop) {
      flex-direction: row;
      align-items: center;
      align-items: flex-start;
    }
  }
  &__title-block,
  &__tip {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: rem(35);

    .title {
      font-weight: 400;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: rem(8);
    }
  }

  &__block {
    flex: 1 1 33%;
    &--second {
      text-align: center;
      span {
        margin: 0 auto;
      }
    }
    &--third {
      text-align: right;
      span {
        margin-left: auto;
      }
    }
  }

  &__label {
    display: flex;
    // justify-content: space-between;
    &--up {
      margin-bottom: rem(16);
      font: $f-light rem(13) / rem(22) $f-text;
      @include breakpoint(desktop) {
        font-size: rem(17);
        line-height: rem(30);
      }
    }
    &--down {
      margin-top: rem(10);
      font: $f-thin rem(20) / rem(30) $f-text;
      @include breakpoint(desktop) {
        font-size: rem(32);
        line-height: rem(42);
      }
    }
  }

  &__progress-bar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: rem(50);
    background: $c-savings;
  }

  &__progress-line {
    height: 120%;
    border-left: 1px solid $c-savings-dark;
  }

  &__tab-value {
    font: $f-medium rem(20) / rem(30) $f-text;
    @include breakpoint(desktop) {
      font: $f-medium rem(26) / rem(30) $f-text;
    }
  }

  &__tab-percent {
    @extend %t-tab;
    color: $c-black;
    padding: rem(5) rem(10);
    background: #ededed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font: $f-regular rem(16) / rem(30) $f-text;
    flex: 1 1 auto;
    border-right: 2px solid $c-white;
    border-bottom: 2px solid #d8d8d8;
    &:last-of-type {
      border-right: 0;
    }
    @include breakpoint(desktop) {
      padding: rem(4.8) rem(8);
      padding: 0.3rem 0.5rem;
      font: $f-regular rem(20) / rem(30) $f-text;
    }
    &--active {
      background: $c-white;
      color: $c-black;
      font: $f-regular rem(16) / rem(30) $f-text;
      border-top: 3px solid $c-primary;
      border-right: 0;
      border-bottom: 0;
      @include breakpoint(desktop) {
        font: $f-regular rem(18) / rem(30) $f-text;
      }

      span {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

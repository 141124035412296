.tips {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: rem(90) auto;

  &__title {
    font-weight: 400;
    font-size: rem(32);
    line-height: rem(42);
  }
  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(24);
    margin-top: rem(24);
    @include breakpoint(desktop) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__items {
    display: flex;
    position: relative;
    flex-direction: column;
    text-decoration: none;
    width: 100%;
    height: 100%;
    background-color: $c-white;
    &:hover {
      .resources__items--image {
        filter: brightness(0.7);
      }
      .resources__items--content {
        background-color: $c-bg-resources-content;
      }
      text-decoration: none;
    }

    &--image {
      display: none;
      @include breakpoint(desktop) {
        display: block;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--content {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding: rem(18) rem(30);
      color: $c-black;
      text-decoration: none;

      &__title {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        gap: rem(10);
        margin-bottom: rem(22);

        @include breakpoint(desktop) {
          margin-bottom: rem(10);
          min-height: rem(60);
        }

        h3 {
          font-size: rem(20);
          font-weight: 400;
          line-height: rem(30);
        }

        span {
          margin-right: rem(10);
          font-weight: 400;
          font-size: rem(30);
          color: $c-primary;
        }
      }

      &__description {
        margin-top: rem(18);

        @include breakpoint(desktop) {
          min-height: rem(130);
        }
        p {
          font-weight: 300;
          font-size: rem(16);
          line-height: rem(26);
        }
      }

      &__link {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: rem(10) rem(25);
        margin-top: rem(30);
        border: rem(1) solid #878787;
        margin: rem(18) rem(30);

        &:hover {
          background-color: $c-bg-gray;
          text-decoration: none;
        }

        p {
          font-weight: 500;
          font-size: rem(15);
          line-height: rem(26);
        }
      }
    }
  }
}

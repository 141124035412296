.survey {
  // height: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    // min-height: 100vh;

    .stepper {
      flex-grow: 1;
    }
  }
}

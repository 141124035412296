.no-match-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 78px);
  @include breakpoint(tablet) {
    width: 60%;
    margin: 0 auto;
    min-height: calc(100vh - 90px);
  }
}

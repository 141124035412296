.custom-select {
  display: flex;
  flex-direction: grid;
  flex-wrap: wrap;

  @include breakpoint(desktop) {
    flex-direction: grid;
    flex-wrap: wrap;
  }

  &-container {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: rem(10);
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    margin-top: rem(12);
    width: 100%;
    min-height: rem(127);
    border: rem(2) solid $c-border-select;
    border-radius: rem(6);
    cursor: pointer;
    transition: 0.2s ease;

    &:first-child {
      margin-top: rem(24);

      @include breakpoint(tablet) {
        margin-top: rem(12);
      }
    }

    @include breakpoint(tablet) {
      margin-right: rem(20);
      width: auto;
      min-width: rem(180);
      min-height: rem(130);
    }

    @include breakpoint(desktop) {
      margin-right: rem(20);
      &:hover {
        border: rem(2) solid $c-primary;
      }
    }

    &-input {
      display: flex;
      position: relative;
      align-items: flex-end;
      margin-top: 15px;
      z-index: -1;
    }

    input {
      &[type="radio"] {
        vertical-align: middle;
        width: rem(16);
        height: rem(16);
        margin-right: rem(8);
        padding: 0;
        border-radius: rem(10);
        border: rem(1) solid $c-border-select;
        cursor: pointer;
        appearance: none;

        &:focus {
          outline: none;
        }

        &:checked {
          border: rem(1) solid $c-secondary;
          box-shadow: inset 0 0 0 rem(4) $c-secondary;
        }
      }

      &[type="checkbox"] {
        margin-right: rem(8);
      }
    }

    &--card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-height: rem(127);
      border-radius: rem(6);

      p {
        padding: rem(10) rem(24) 0;
      }
    }
  }

  &-container--selected {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    margin-top: rem(12);
    gap: rem(10);
    width: 100%;
    min-height: rem(127);
    border: rem(2) solid $c-primary;
    border-radius: rem(6);
    cursor: pointer;
    transition: 0.2s ease;

    &:first-child {
      margin-top: rem(24);

      @include breakpoint(tablet) {
        margin-top: rem(12);
      }
    }
    // &:hover {
    //   border: rem(2) solid $c-primary;
    // }

    @include breakpoint(tablet) {
      margin-right: rem(20);
      width: auto;
      min-width: rem(180);
      min-height: rem(130);
    }

    @include breakpoint(desktop) {
      margin-right: rem(20);
    }

    &-input {
      display: flex;
      position: relative;
      align-items: flex-end;
      margin-top: 15px;
      z-index: -1;
    }

    input {
      &[type="radio"] {
        vertical-align: middle;
        width: rem(16);
        height: rem(16);
        padding: 0;
        margin-right: rem(8);
        border-radius: rem(10);
        border: rem(1) solid $c-border-select;
        cursor: pointer;
        appearance: none;

        &:focus {
          outline: none;
        }

        &:checked {
          border: rem(1) solid $c-secondary;
          box-shadow: inset 0 0 0 rem(4) $c-secondary;
        }
      }

      &[type="checkbox"] {
        margin-right: rem(8);
        accent-color: $c-secondary;
        z-index: 100;
      }
    }

    &--card {
      display: flex;
      min-height: rem(127);
      border-radius: rem(6);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        padding: rem(10) rem(24) 0;
      }
    }
  }
  select {
    flex: 1 1 auto;
    color: $c-black;
    cursor: pointer;
    background: url("../../assets/images/select-arrow.png") right center
      no-repeat #fff;
    &.disabled {
      color: $c-text-disabled;
      @extend %t-survey;
    }
  }

  select::-ms-expand {
    display: none;
  }

  option {
    &:first-child {
      display: none;
    }
    &.disabled {
      color: $c-text-disabled;
    }
  }
}

.step-buttons {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(16);

    @include breakpoint(desktop) {
      padding: 0 10%;
    }
  }

  &__counter {
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(23);
  }

  &--bottom {
    display: flex;
    align-items: flex-end;
    button {
      padding: rem(8) rem(40);
    }
    @include breakpoint(mobile) {
      button {
        padding: rem(12) rem(61);
      }
    }
  }
}

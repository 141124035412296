.secondary-hero {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;

  @include breakpoint(tablet) {
    max-height: 80vh;
    justify-content: end;
  }

  @include breakpoint(desktop) {
    margin-bottom: rem(100);
  }

  &__image {
    width: 100%;
    object-fit: cover;
    object-position: 86%;
  }

  &__button {
    @extend %t-button;
    display: flex;
    width: 90%;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding: rem(22) rem(44);
    bottom: rem(-30);
    font-size: rem(20);
    font-weight: 300;
    line-height: rem(26);
    background: $c-primary;
    color: $c-white;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @include breakpoint(tablet) {
      margin: 0;
      padding: rem(20) rem(46);
      width: rem(350);
      right: 40%;
    }

    @include breakpoint(desktop) {
      right: 90%;
    }

    &:hover {
      background: $c-primary-dark;
    }
  }
}

.custom-radio-container {
  display: flex;
  position: relative;
  background: #fff;
  overflow: hidden;
  $primary-color: #cccccc;
  cursor: pointer;

  &__radio {
    display: inline-flex;
    padding: rem(6);
    border-radius: rem(50);
    transition: 0.2s ease;

    &:hover,
    &:focus-within {
      background: rgba($primary-color, 0.1);
    }

    input {
      vertical-align: middle;
      padding: 0;
      margin-right: rem(8);
      width: rem(20);
      height: rem(20);
      border-radius: rem(10);
      background: none;
      border: 0;
      box-shadow: inset 0 0 0 rem(1) $primary-color;
      box-shadow: inset 0 0 0 rem(1.5) $primary-color;
      appearance: none;
      transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:checked {
        box-shadow: inset 0 0 0 rem(6) red;
      }
    }

    label {
      cursor: pointer;
    }

    label:not(:last-of-type) {
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      margin-right: rem(22);
      cursor: pointer;
    }
  }

  &--big {
    select {
      margin: 0 rem(20);
      padding-right: rem(40);
      @extend %t-survey-regular;
    }
  }

  &--normal {
    select {
      margin: rem(14) rem(20);
      padding-right: rem(20);
      @extend %t-h5;
    }
  }

  &--small {
    select {
      margin: rem(0) rem(10);
      padding-right: rem(30);
      @extend %t-h3;

      &.disabled {
        @extend %t-h3;
      }
    }
  }
}

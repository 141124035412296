.finance {
  background: $c-bg-gray;
  padding-bottom: rem(80);
  &__wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50px 1fr 100px;
    grid-template-columns: 50px 1fr 100px;
    -ms-grid-rows: -webkit-min-content 200px -webkit-min-content 50px;
    -ms-grid-rows: min-content 200px min-content 50px;
    grid-template-rows: -webkit-min-content 200px -webkit-min-content 50px;
    grid-template-rows: min-content 200px min-content 50px;
    width: 100%;
    @include breakpoint(tablet) {
      -ms-grid-rows: -webkit-min-content -webkit-min-content;
      -ms-grid-rows: min-content min-content;
      grid-template-rows: -webkit-min-content -webkit-min-content;
      grid-template-rows: min-content min-content;
      -ms-grid-columns: 1fr 400px 50px 390px 370px 1fr;
      grid-template-columns: 1fr 400px 50px 390px 370px 1fr;
    }
  }
  &__title {
    padding-top: rem(52);
    grid-row: 1 / 2;
    grid-column: 1 / 4;
    padding: rem(50) $g-padding-mobile;
    @include breakpoint(tablet) {
      margin-right: rem(50);
      padding-top: rem(100);
      grid-row: 1 / 3;
      grid-column: 2 / 3;
      -ms-grid-row: 1 !important;
      -ms-grid-column: 2 !important;
    }
  }
  &__wrapper-image {
    grid-row: 2 / 4;
    grid-column: 2 / 4;
    @include breakpoint(tablet) {
      grid-row: 1 / 3;
      grid-column: 3 / 7;
      -ms-grid-column-span: 5;
      z-index: 1;
      margin-top: rem(-50);
      -ms-grid-row: 1 !important;
      -ms-grid-column: 3 !important;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 60% center;
    @include breakpoint(tablet) {
      object-position: center;
    }
  }

  &__box {
    background: $c-white;
    grid-row: 3 / 5;
    grid-column: 1 / 3;
    padding: rem(40) rem(32);
    position: relative;
    top: 0;
    @include breakpoint(tablet) {
      padding: rem(32) rem(40);
      grid-row: 2 / 3;
      grid-column: 4 / 5;
      z-index: 2;
      padding: rem(40);
      top: 30px;
      -ms-grid-row: 2 !important;
      -ms-grid-column: 4 !important;
    }
  }
  &__link {
    font-weight: $f-medium;
    color: $c-select;
    text-decoration: underline;
  }
}

.header-results {
  position: relative;
  width: 100%;
  min-height: 55vh;
  // max-height: 400px;

  @include breakpoint(tablet) {
    height: 95vh;
    min-height: 700px;
    max-height: 720px;
  }
  &__logo {
    position: relative;
    z-index: 2;
    text-align: center;
    background: $c-white;
    display: flex;
    justify-content: center;
    padding: rem(14);
    @include breakpoint(tablet) {
      text-align: left;
      justify-content: flex-start;
      padding: rem(24) rem(20);
    }

    .logo__img {
      display: none;
      @include breakpoint(tablet) {
        display: inline-block;
      }
    }
    .logo__img--mobile {
      display: inline-block;
      @include breakpoint(tablet) {
        display: none;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    &--bad {
      object-position: bottom;
    }
  }

  &--not-able {
    max-height: none;
  }
}

.header-gradient {
  background: linear-gradient(to bottom, rgba($c-black, 0), rgba($c-black, 1));
  width: 100%;
  position: absolute;
  height: rem(120);
  z-index: 1;
  bottom: 0;
  @include breakpoint(tablet) {
    height: -webkit-fill-available;
    background: linear-gradient(180deg, rgba(48, 51, 53, 0) 0%, rgba(0, 0, 0, 0.5) 89.58%);
  }
}

.step {
  display: flex;
  flex-direction: column;
  color: #202b50;
  @include breakpoint(desktop) {
    padding-top: rem(50);
  }

  &__content {
    margin-top: rem(10);
    padding: 0 rem(16);
    margin-left: 0;
    height: inherit;

    @include breakpoint(desktop) {
      margin-top: rem(0);
      margin-left: 10%;
    }
  }

  &__content-debts {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: flex-start;
    .custom-select {
      flex-wrap: wrap;
      width: 100%;
    }
    @include breakpoint(desktop-medium) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .custom-select {
        flex-wrap: nowrap;
        width: auto;
      }
    }
  }

  &__savings {
    .step__essential-item {
      margin-bottom: 0;
    }
  }

  &__essential {
    display: inline-block;
    width: 100%;

    &--debts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: rem(48);
      width: 100%;

      @include breakpoint(tablet) {
        display: inline-block;
        margin-top: rem(12);
        width: auto;
      }
    }
  }

  &__navigation {
    // position: fixed;
    width: 100%;
    padding-bottom: rem(30);
    bottom: 0;
    background: $c-white;
    margin-top: rem(30);

    @include breakpoint(desktop) {
      position: relative;
      // margin-top: rem(200);
      border-top: rem(1) solid #f7f7f7;
      padding: rem(30) 0 rem(30) 0;
    }
  }

  &__question-debts {
    display: flex;
    position: relative;
  }

  &__insurances {
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet) {
      width: 50%;
    }

    &--block {
      display: flex;
      padding: $g-padding-mobile;
    }
  }

  &__debts {
    display: flex;
    justify-content: space-between;
    .s-table__input {
      margin-right: rem(8);
      fieldset {
        display: flex;
        flex-direction: column;
        .step-label {
          font-size: rem(14);
          font-weight: 350;
          line-height: rem(18);
          color: $c-help-text;
        }
        span {
          font-size: rem(38);
          font-weight: 300;
          line-height: rem(31);

          @include breakpoint(tablet) {
            justify-content: initial;
            font-size: rem(24);
            line-height: rem(42);
          }
        }
      }
    }
  }

  &__label {
    display: block;
  }

  &__essential-item {
    display: inline-block;
    margin-right: rem(32);
    margin-bottom: rem(16);
    width: 100%;
    @include breakpoint(mobile) {
      margin-bottom: rem(24);
      width: initial;
    }
    &--debts {
      width: 100%;
      margin-right: rem(20);
      margin-bottom: rem(8);
      &:last-child {
        margin-right: 0;
      }
      border-radius: rem(5);
      padding: rem(8);
      border: rem(2) solid #e9eaed;
      @include breakpoint(tablet) {
        width: auto;
        margin-bottom: 0;
      }
    }
    .input__wrapper {
      border: rem(1) solid $c-border-select;
      input {
        background: $c-white;
      }
    }

    input {
      border: none !important;
    }
  }

  &__essential-block {
    &__not-selected {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: rem(5);
      padding: rem(12) rem(12) rem(0) rem(12);
      border: rem(2) solid #e9eaed;
    }

    &__selected {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: rem(5);
      padding: rem(12) rem(12) rem(0) rem(12);
      border: rem(2) solid #808080;
    }
  }

  &__esential-title {
    margin-right: auto;
    padding-right: rem(32);
    font-weight: $f-light;
    &--spends {
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(21);
      margin-bottom: rem(8);
    }
    &--debts {
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(21);
    }
  }

  &__essential-block-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__essential-block-item {
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      align-items: center;
    }

    .t-help {
      height: rem(18);
      margin-left: 0;
      font-size: rem(14);
      font-weight: 300;
      line-height: rem(18);
      color: #8f95a7;
    }
  }

  &__essential-block-spend {
    display: flex;
    align-items: left;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(mobile) {
      flex-direction: column;
    }

    .s-table__input {
      margin-right: rem(10);
      margin-bottom: rem(8);
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      @include breakpoint(tablet) {
        margin-right: rem(32);
        display: inline-block;
      }
      &:last-child {
        margin-right: 0;
      }
      input {
        padding: 0;
      }
    }

    &__essential-icon {
      padding-right: rem(15);
      @include icon() {
        font-size: rem(24);
      }
      color: $c-primary;
      &--car {
        &:before {
          content: $icon-car;
        }
      }
      &--publicTransport {
        &:before {
          content: $icon-publicTransport;
        }
      }
      &--insuranceMedical {
        &:before {
          content: $icon-insuranceMedical;
        }
      }
      &--counciltax {
        &:before {
          content: $icon-counciltax;
        }
      }
      &--childcare {
        &:before {
          content: $icon-childcare;
        }
      }
      &--other {
        font-size: rem(16);
        &:before {
          font-size: rem(6);
          content: $icon-other;
        }
      }
      &--utilities {
        &:before {
          content: $icon-utilities;
        }
      }
      &--rent {
        &:before {
          content: $icon-housing;
        }
      }
      &--mortgage {
        &:before {
          content: $icon-housing;
        }
      }
      &--groceries {
        &:before {
          content: $icon-groceries;
        }
      }
      &--mediaEntertainment {
        &:before {
          content: $icon-mediaEntertainment;
        }
      }
      &--creditCard {
        &:before {
          content: $icon-spending;
        }
      }
      &--loans {
        &:before {
          content: $icon-saving;
        }
      }
    }

    &__content {
      grid-row: 2 / 3;
      width: 100%;
      margin-top: rem(35);
      @include breakpoint(desktop) {
        margin-top: rem(0);
      }
    }

    &__question {
      &--step1 {
        .input__wrapper {
          margin: rem(5) 0;
        }
      }
    }
  }
  &__spends-sum,
  &__mortgage-value {
    margin: 0 rem(15);
    font-weight: $f-regular;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;

    &--title {
      margin-bottom: rem(20);
    }
    &--subtitle {
      font-size: rem(24);
      font-weight: $f-light;
      line-height: rem(35);
      padding-bottom: rem(20);
    }
    @include breakpoint(desktop) {
      align-items: flex-start;
      flex-direction: column;
      &--title {
        margin-bottom: rem(40);
      }
    }
  }

  &__tip {
    font-size: rem(24);
    font-weight: 300;
    line-height: rem(31);
    max-width: rem(600);
    padding: rem(16) 0;
    color: #202b50;
    margin-top: rem(25);
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: rem(24);
      font-weight: 300;
      line-height: rem(35);
    }
  }

  &__icons {
    display: none;
    @include breakpoint(desktop) {
      display: flex;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-right: rem(20);
        p {
          font-size: rem(12);
          font-weight: 500;
          line-height: rem(10);
          margin-left: rem(5);
        }
      }
    }
  }

  &__message {
    font-size: rem(23);
    line-height: rem(30);
    font-weight: 300;
    // position: absolute;
    max-width: rem(600);
    span {
      font-family: "Segoe UI Emoji";
    }

    margin-top: rem(40);
  }

  &__alert {
    display: inline-block;
    padding: rem(8);
    background-color: $c-primary-light;
    color: $c-white;
  }
}

.step-home {
  color: $c-white;
  .input__wrapper {
    background-color: rgba(255, 255, 255, 0.5);
    input {
      background: $c-white !important;
      // opacity: 0.5;
    }
  }
}

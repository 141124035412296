.cta-link {
  @extend %t-button;
  color: $c-primary;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  @include icon($icon-arrow) {
    padding-bottom: rem(16);
    color: $c-primary;
  }
}

.link {
  @extend %t-h6;
  color: $c-select;
  font-size: rem(16);

  &:hover {
    text-decoration: underline;
  }
}

.debt-overdraft {
  display: block;
  position: relative;
  margin-top: rem(90);

  &__title {
    h2 {
      font-weight: 400;
      font-size: rem(32);
      line-height: rem(42);
    }
  }

  &__description {
    display: block;
    margin-top: rem(11);
    p {
      font-weight: 350;
      font-size: rem(20);
      line-height: rem(26);
      color: #767676;
    }
  }

  &__content {
    display: block;
    margin-top: rem(40);
    padding: rem(20) 0;
    width: 100%;

    @include breakpoint(desktop) {
      padding-left: rem(60);
    }

    &__item {
      display: inline-block;
      width: 100%;
      padding: rem(10);
      margin-top: rem(30);
      font-size: rem(20);
      line-height: rem(45);

      @include breakpoint(tablet) {
        width: 50%;
      }
      i {
        font-size: rem(40);
        font-weight: 500;
        color: #db0011;
      }

      h3 {
        font-weight: 400;
      }
      p {
        font-weight: 350;
      }
      a {
        padding: rem(10) rem(15);
        font-weight: 400;
        font-size: rem(15);
        border: rem(1) solid $c-border-select;

        &:hover {
          text-decoration: none;
          background-color: $c-button-tip-hover;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  height: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $c-white;
  width: 100%;
  z-index: 1000;
  animation: appear 0.5s linear;

  @include breakpoint(tablet) {
    height: auto;
    width: 60%;
  }
  &__close {
    color: $c-primary;
    position: absolute;
    right: 5%;
    padding: 0.5rem 1.5rem;
    z-index: 10;
    @include breakpoint(tablet) {
      right: 0;
    }
  }

  &__content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    justify-content: space-between;
    text-align: center;
    padding: 6rem 3rem;
    position: relative;

    @include breakpoint(tablet) {
      height: -webkit-fill-available;
    }

    span {
      padding: 2rem;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-evenly;
    @include breakpoint(tablet) {
      flex-direction: initial;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  height: 100vh;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

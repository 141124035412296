.step-progress-bar {
  display: block;
  position: relative;
  width: 100%;
  height: rem(4);
  margin-top: rem(34);
  background-color: $c-bg-bar;
  transform: skewX(-60deg);
  transform-origin: top;

  &__track {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $c-primary;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 2s;
  }
}

.footer {
  @extend %t-footer;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: $c-black;
  color: $c-white;

  @include breakpoint(tablet) {
    flex-direction: row;
    height: rem(90);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: $g-max-width-small + $g-padding-mobile * 2;
    padding-left: $g-padding-mobile;
    padding-right: $g-padding-mobile;
    margin: rem(60) 0;

    @include breakpoint(tablet) {
      padding: rem(32) rem(120);
      flex-direction: row;
      margin: 0;
    }

    li {
      margin-right: 0;
      padding-bottom: rem(16);

      &:last-of-type {
        padding: 0;
      }

      @include breakpoint(tablet) {
        margin-right: rem(48);
        padding: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .link {
    color: $c-white;
  }
}

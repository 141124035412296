.home {
  background-color: $c-bg-landing-content;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}
.hero {
  width: 100%;

  &__data-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    padding: rem(24);
    background-image: url("../../assets/images/hero_home_mobile.webp");
    background-size: cover;

    @include breakpoint(mobile) {
      background-image: url("../../assets/images/hero_home.webp");
      background-position: 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: none;
      width: 100%;
    }

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: none;
      padding: rem(170) rem(120) rem(50) rem(120);
    }

    @include breakpoint(desktop-extra-large) {
      background-position-y: 25%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  &__title {
    width: 70%;
    min-width: 17.5rem;
    max-width: rem(450);
    margin-bottom: rem(50);
    color: $c-white;

    @include breakpoint(mobile) {
      width: 100%;
    }

    @include breakpoint(tablet) {
      width: 70%;
      min-width: rem(600);
    }
  }

  &__subtitle {
    width: 100%;
    font-size: rem(20);
    font-weight: 300;
    line-height: rem(30);
    color: $c-white;
    span {
      display: block;
    }

    @include breakpoint(tablet) {
      font-size: rem(28);
      font-weight: 200;
      line-height: rem(36);
      max-width: rem(560);
    }
  }

  img {
    max-width: none;
  }
}

.content {
  position: relative;
  padding: rem(24) rem(24) rem(34) rem(24);
  color: $c-white;
  background-color: $c-bg-landing-content;

  @include breakpoint(tablet) {
    padding: rem(0) rem(120);
    margin-top: rem(20);
    margin-bottom: rem(40);
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__goals-text {
    display: none;

    @include breakpoint(tablet) {
      display: block;
      margin-left: rem(42);
      font-size: rem(19);
      line-height: 1.5;
      opacity: 0.7;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: rem(50);
    }
  }

  &__text-item {
    font-size: rem(18);
    font-weight: 300;
    line-height: rem(21);
    margin-top: rem(30);
    opacity: 0.8;

    &__link {
      text-decoration: underline;
    }

    &__opacity {
      opacity: 0.4;
    }

    @include breakpoint(tablet) {
      margin-top: rem(5);
    }
  }

  &__bullets {
    @include breakpoint(tablet) {
      padding-right: rem(30);
    }
  }

  &__text-extra {
    font-size: rem(19);
    font-weight: 300;
    line-height: rem(38);
  }
}

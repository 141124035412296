.balance {
  display: flex;
  flex-direction: column;
  @include breakpoint(tablet) {
    flex-direction: row;
  }

  &__content {
    width: 100%;

    @include breakpoint(tablet) {
      display: flex;
    }
    @include breakpoint(tablet) {
      margin-bottom: rem(50);
    }

    &__description {
      background-color: $c-white;
      height: fit-content;
      padding: $g-padding-mobile;
      @include breakpoint(tablet) {
        max-width: rem(564);
        margin-top: rem(50);
      }
    }

    &__progressbar {
      display: flex;
      width: -webkit-fill-available;
      flex-direction: column;
      @include breakpoint(tablet) {
        padding: $g-padding-mobile;
        padding-left: rem(40);
        padding-right: 0;
      }
      @include breakpoint(desktop) {
        transform: translateY(90px);
      }
    }
  }

  &__bg {
    object-fit: cover;
    width: 100%;
    @include breakpoint(tablet) {
      width: 50%;
    }
  }
}

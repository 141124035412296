.results-bad-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: rem(16);
  padding: rem(30) rem(45);
  width: 100%;
  min-height: rem(240);
  margin-top: rem(50);
  border-top: rem(5) solid $c-primary;
  background-color: $c-white;

  &__title {
    h2 {
      font-weight: 500;
      font-size: rem(22);
      line-height: rem(26);
    }
  }

  &__description {
    font-size: rem(16);
    font-weight: 300;
    line-height: rem(21);
    color: $c-help-text;
  }

  &__icon {
    i {
      color: $c-primary;
    }
  }

  &__label {
    a {
      display: inline-flex;
      align-items: center;
      padding: rem(12) rem(21);
      text-decoration: none;
      font-weight: 400;
      color: $c-white;
      background-color: $c-primary;

      &:hover {
        background-color: $c-primary-dark;
      }

      i {
        margin-left: rem(10);
      }
    }
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-left: rem(10);
  padding: rem(16);
  z-index: 3;
  position: relative;
  width: 100%;
  background: $c-white;

  .logo {
    img {
      @include breakpoint(tablet) {
        width: rem(177);
        height: rem(51);
      }
    }
  }
}

.resources {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: rem(90) auto;

  &__title {
    font-weight: 400;
    font-size: rem(32);
    line-height: rem(42);
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-top: rem(20);

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  &__items {
    display: grid;
    // position: relative;
    flex-direction: column;
    margin-bottom: rem(12);
    text-decoration: none;
    width: 100%;

    @include breakpoint(tablet) {
      justify-content: space-between;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: rem(30);
      }
    }

    &:hover {
      .resources__items--image {
        filter: brightness(0.7);
      }
      .resources__items--content {
        background-color: $c-bg-resources-content;
      }
      text-decoration: none;
    }

    &--image {
      display: none;
      @include breakpoint(tablet) {
        display: block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--content {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding: rem(18) rem(30);
      background-color: $c-bg-resources;
      color: $c-white;
      text-decoration: none;

      @include breakpoint(tablet) {
        padding: rem(18) rem(25);
        min-height: 225px;
      }
      @include breakpoint(desktop) {
        min-height: 175px;
      }

      @include breakpoint(desktop-large) {
        min-height: 155px;
      }

      &__title {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(22);

        @include breakpoint(tablet) {
          margin-bottom: rem(10);
        }

        h3 {
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(21);
        }

        span {
          font-weight: 400;
          font-size: rem(30);
          color: $c-primary;
        }
      }

      &__description {
        p {
          font-weight: 200;
          font-size: rem(15);
          line-height: rem(19);
        }

        &--item {
          margin-bottom: rem(10);
        }
      }
    }
  }
}

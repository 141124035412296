.scoring {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: $c-black;
  align-items: center;
  color: $c-white;
  height: 0;
  min-height: 320px;
  @include breakpoint(tablet) {
    min-height: 0;
    position: absolute;
    top: 20%;
    margin: 0 auto rem(32);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0 rem(16);
    padding: 0 rem(16);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(30px);
    transform: translateY(-350px);

    @include breakpoint(tablet) {
      padding: rem(32) rem(64) 0;
      transform: translateY(0);
    }

    &--header {
      @include breakpoint(tablet) {
        display: inline-flex;
      }
    }
  }

  &__text {
    margin-bottom: rem(18);
    display: block;
    width: 100%;

    h1 {
      @extend %t-h2;
      text-align: center;
      @include breakpoint(tablet) {
        text-align: left;
      }
    }
  }

  &__score {
    text-align: center;
  }

  &__edit {
    color: $c-white;
    @extend %t-h6;
    cursor: pointer;
    margin: rem(20) auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.2);

    @include breakpoint(tablet) {
      margin: rem(20) 0 0 0;
    }

    &:hover {
      text-decoration: underline;
    }

    @include icon($icon-edit) {
      color: $c-primary;
    }
  }

  &__info {
    &--button {
      width: 100%;
      padding: rem(12) rem(21);
      gap: rem(10);
      height: rem(45);
      @include breakpoint(desktop) {
        width: rem(240);
        margin-right: rem(20);
      }

      &:hover {
        text-decoration: none;
        background-color: $c-bg-light-secondary;
      }
    }
  }
}

.score {
  color: $c-border-select;
  font-size: rem(18);
  font-weight: $f-thin;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(tablet) {
    width: 60%;
    display: flex;
    align-items: flex-end;
  }

  &__number {
    padding: rem(10) 0;
    color: $c-white;
    font-size: rem(108);
    line-height: rem(108);
  }

  &__info {
    @extend %t-p;
    transform: translateY(-25px);
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $c-white;
    align-items: center;
    padding: 0 rem(16);
    align-items: baseline;

    @include breakpoint(tablet) {
      padding: 0;
    }
    @include breakpoint(desktop) {
      padding: 0 rem(16) 0 0;
      display: flex;
      flex-direction: row;
    }

    a {
      color: $c-white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      margin-right: rem(8);
    }

    &-howTo-link {
      display: flex;
      @include breakpoint(tablet) {
        display: contents;
      }
    }
    &-download-link {
      @extend %t-p;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);

      &:hover {
        &:hover {
          text-decoration: none;
          background-color: $c-bg-light-secondary;
        }
      }

      .icon {
        color: $c-primary;
        margin-left: rem(10);
      }
    }
  }
}

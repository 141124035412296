.resilience {
  &__header {
    max-width: 100%;
    width: 100%;
    margin: 0 0 rem(24) 0;
    @include breakpoint(tablet) {
      width: 100%;
    }

    &-title {
      @extend %t-h3;
      margin-bottom: rem(20);
    }
    &-text {
      @extend %t-h5;
      color: $c-text-light;
    }
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(15);
    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }
}

.stepper {
  display: flex;
  flex-direction: column;

  &__header {
    display: none;
  }

  &__section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &:empty {
      display: none;
    }

    .step {
      min-height: calc(100vh - 85px);
      justify-content: space-between;
      flex-grow: 1;
      // @include breakpoint(tablet) {
      //   min-height: 90vh;
      // }
    }
  }
}

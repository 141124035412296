html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 100%;
}

body {
  font-family: $f-text, sans-serif;
  color: $c-text;
  font: rem(14) / rem(17) $f-text;
}

// Headline
%t-h1,
.t-h1 {
  font: $f-thin rem(52) / rem(61) $f-text;

  @include breakpoint(desktop) {
    font-size: rem(88);
    line-height: rem(114);
  }
}

%t-h2,
.t-h2 {
  font: $f-thin rem(40) / rem(47) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(60);
    line-height: rem(80);
  }
}

%t-survey,
.t-survey {
  font: $f-light rem(35) / rem(40) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(40);
    line-height: rem(60);
  }
}

%t-survey-regular,
.t-survey-regular {
  font: $f-regular rem(40) / rem(47) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(60);
    line-height: rem(78);
  }
}

%t-h3,
.t-h3 {
  font: $f-thin rem(32) / rem(42) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(44);
    line-height: rem(57);
  }
}

%t-h4,
.t-h4 {
  font: $f-light rem(20) / rem(30) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(28);
    line-height: rem(38);
  }
}

%t-h5,
.t-h5 {
  font: $f-light rem(13) / rem(22) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(17);
    line-height: rem(30);
  }
}

%t-h6,
.t-h6 {
  font: $f-light rem(13) / rem(22) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(16);
    line-height: rem(26);
  }
}

%t-tab,
.t-tab {
  font: $f-light rem(18) / auto $f-text;
}

%t-head-table,
.t-head-table {
  font: $f-light rem(13) / rem(22) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(16);
    line-height: rem(22);
  }
}

// Texts
%t-p,
.t-p {
  font: $f-regular rem(14) / rem(17) $f-text;
}

%t-button,
.t-button {
  font: $f-regular rem(18) $f-text;
}

// %-input,
// .t-input {
//   font: $f-regular rem(25) / rem(23) $f-text;
// }

%t-help,
.t-help {
  font: $f-light rem(13) / rem(18) $f-text;
  color: $c-select;
  margin-left: rem(40);
}

%t-footer,
.t-footer {
  font: $f-light rem(14) / rem(26) $f-text;
  @include breakpoint(tablet) {
    font-size: rem(16);
    line-height: rem(19);
  }
}

.t-number {
  font: $f-bold rem(44) / rem(57) $f-text;
}

%t-finance,
.t-finance {
  font: $f-light rem(20) / rem(30) $f-text;

  @include breakpoint(tablet) {
    font-size: rem(24);
    line-height: rem(38);
  }
}

[role='button'] {
  cursor: pointer;
}

strong {
  font-weight: $f-bold;
}

a {
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

%t-gray,
.t-gray {
  color: $c-text-gray;
}

.icon {
  @include icon-styles();

  &--2x {
    font-size: 2rem;
  }
}

.icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}

.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}

.icon-select-arrow {
  &:before {
    content: $icon-select-arrow;
  }
}

.icon-help {
  &:before {
    content: $icon-help;
  }
}

.icon-error .path1 {
  &:before {
    content: $icon-error-path1;
    color: rgb(168, 0, 11);
  }
}

.icon-error .path2 {
  &:before {
    content: $icon-error-path2;
    margin-left: -1.125em;
    color: rgb(255, 255, 255);
  }
}

.icon-error .path3 {
  &:before {
    content: $icon-error-path3;
    margin-left: -1.125em;
    color: rgb(255, 255, 255);
  }
}

.icon-spending {
  &:before {
    content: $icon-spending;
    color: #db0011;
  }
}

.icon-saving {
  &:before {
    content: $icon-saving;
    color: #db0011;
  }
}

.icon-borrowing {
  &:before {
    content: $icon-borrowing;
    color: #db0011;
  }
}

.icon-light {
  &:before {
    content: $icon-light;
  }
}

.icon-car {
  &:before {
    content: $icon-car;
  }
}

.icon-marker {
  &:before {
    content: $icon-marker;
  }
}

.icon-new-window {
  color: $c-primary;
  margin-left: rem(10);

  &:before {
    content: $icon-new-window;
  }
}

.icon-publicTransport {
  &:before {
    content: $icon-publicTransport;
  }
}

.icon-insuranceMedical {
  &:before {
    content: $icon-insuranceMedical;
  }
}

.icon-counciltax {
  &:before {
    content: $icon-counciltax;
  }
}

.icon-childcare {
  &:before {
    content: $icon-childcare;
  }
}

.icon-other {
  &:before {
    content: $icon-other;
  }
}

.icon-utilities {
  &:before {
    content: $icon-utilities;
  }
}

.icon-housing {
  &:before {
    content: $icon-housing;
  }
}

.icon-groceries {
  &:before {
    content: $icon-groceries;
  }
}

.icon-mediaEntertainment {
  &:before {
    content: $icon-mediaEntertainment;
  }
}

.icon-umbrella {
  &:before {
    content: $icon-umbrella;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-secure {
  &:before {
    content: $icon-secure;
  }
}

.icon-plan {
  &:before {
    content: $icon-plan;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-check {
  color: #34d399;
  &:before {
    content: $icon-check;
  }
}

.icon-warning {
  color: #fbbf24;
  &:before {
    content: $icon-warning;
  }
}

.icon-sixty {
  &:before {
    content: $icon-sixty;
  }
}

.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}

.icon-house {
  &:before {
    content: $icon-house;
  }
}

.icon-creditCard {
  &:before {
    content: $icon-credit-card;
  }
}

.icon-dollar {
  &:before {
    content: $icon-dollar;
  }
}

.icon-seven {
  &:before {
    content: $icon-seven;
  }
}

.header-not-results {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: $c-black;
  align-items: center;
  color: $c-white;
  height: 0;
  min-height: rem(410);
  margin: 0 auto;

  @include breakpoint(tablet) {
    min-height: 0;
    position: absolute;
    top: rem(170);
    margin: 0 auto rem(32);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 85%;
    margin: 0 rem(16);
    padding: rem(16) rem(16) rem(52) rem(16);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(rem(30));
    transform: translateY(rem(-385));

    @include breakpoint(tablet) {
      padding: rem(32) rem(64) rem(150) rem(64);
      transform: translateY(0);
    }

    &--header {
      @include breakpoint(tablet) {
        display: inline-flex;
      }
    }
  }

  &__title {
    padding-top: rem(45);
    h1 {
      font-size: rem(25);
      font-weight: 300;
      line-height: rem(35);
      @include breakpoint(mobile) {
        font-size: rem(30);
        font-weight: 300;
        line-height: rem(42);
      }
    }

    @include breakpoint(desktop) {
      h1 {
        font-size: rem(40);
        font-weight: 300;
        line-height: rem(52);
      }
    }
  }

  &__content {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(8);
    top: 85%;
    left: 0;
    right: 0;
    padding: rem(32) 0;
    color: $c-black;

    @include breakpoint(tablet) {
      gap: rem(20);
      flex-direction: row;
      padding: rem(64) 0;
      top: 50%;
    }
    .results-bad-card {
      padding: rem(20);
      width: 100%;
      margin-top: 0;
      gap: rem(0);
      grid-gap: rem(0);
      min-height: rem(216);
      @include breakpoint(tablet) {
        padding: rem(30) rem(45);
        width: rem(320);
        gap: rem(16);
        min-height: rem(265);
        margin-top: rem(50);
        justify-content: flex-start;
      }
      &__icon {
        min-height: rem(41);
      }
    }
  }
}

.scoring-home {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: none;
  padding: rem(30) rem(42);
  background-color: $c-white;
  transition: ease all 0.5s;

  @include breakpoint(tablet) {
    padding: rem(20) rem(30);
  }

  @include breakpoint(desktop) {
    position: absolute;
    max-width: rem(320);
    padding: rem(16) rem(24);
    left: rem(530);
    bottom: 35%;
    box-shadow: rem(2) rem(2) rem(2) rem(4) rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  @include breakpoint(desktop-medium) {
    max-width: rem(382);
    padding: rem(20) rem(30);
  }

  &__title {
    text-align: left;
    font-size: rem(20);
    font-weight: 300;
    line-height: rem(26);
    color: $c-text-gray;
  }

  &__score {
    margin-top: rem(10);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(18);
    color: $c-text-gray;

    span {
      font-size: rem(44);
      font-weight: 400;
      line-height: rem(57);
      color: $c-black;
    }
  }

  &__subtitle {
    margin-top: rem(20);
    font-size: rem(20);
    font-weight: 300;
    line-height: rem(26);
    color: $c-text-gray;

    span {
      font-weight: 400;
      color: #000;
    }
  }

  &__link {
    margin-top: rem(37);
    color: $c-text-gray;
  }
}
